import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import styled from 'styled-components';

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const RightAlignedNav = styled(Nav)`
  margin-left: auto;
  padding-right: .5rem;
`;

const StyledIcon = styled(Navbar.Brand)`
padding-left: .5rem;
`;

const StyledNavLink = styled(Nav.Link)`
  font: 22px "Avenir LT Pro";
  color: #eee4db;

  &:hover {
    color: #b87856; /* Set the desired color on mouse hover */
  }
`;


function NavbarComponent() {
  return (
    <Navbar collapseOnSelect expand="lg" sticky="top" bg="black" variant="dark">
      <StyledContainer>
        <StyledIcon href="#home">
          <img
            src="https://res.cloudinary.com/dusaigbyn/image/upload/c_thumb,g_face,w_60/v1686262069/AK_Icon_ozvgpy.png"
            alt="Logo"
          />
        </StyledIcon>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      </StyledContainer>
      <Navbar.Collapse id="responsive-navbar-nav">
        <RightAlignedNav>
          <StyledNavLink href="#home">Home</StyledNavLink>
          <StyledNavLink href="#about">About</StyledNavLink>
          <StyledNavLink href="#portfolio">Portfolio</StyledNavLink>
          <StyledNavLink href="#contact">Contact</StyledNavLink>
        </RightAlignedNav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavbarComponent;
