import React, { useState } from "react";
import styled from "styled-components";

const Header = () => {
  return (
    <React.Fragment>
        <header id="home" className="header">
        <BannerImages>
          <TopBanner
            src="https://res.cloudinary.com/dusaigbyn/image/upload/v1686692389/AK_web_design_R2_web-header_3_rgjahm.jpg"
            alt="Top Banner"
          />
        </BannerImages>
        <p className="scrolldown">
          <a className="smoothscroll" href="#about">
            <i className="icon-down-circle"></i>
          </a>
        </p>
      </header>
    </React.Fragment>
  );
};

const BannerImages = styled.div`
  display: flex;
  height: 100%;
`;

const TopBanner = styled.img`
  flex: 1;
  width: 100%;

  @media (max-width: 600px) {
    content: url("https://res.cloudinary.com/dusaigbyn/image/upload/v1686766925/AK_web_design_mobile-header_R3_owivv1.jpg");
    padding-top: 70px;
  }

  @media (min-width: 601px) {
    content: url("https://res.cloudinary.com/dusaigbyn/image/upload/v1686692389/AK_web_design_R2_web-header_3_rgjahm.jpg");
  }
`;

export default Header;