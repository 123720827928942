import React, { Component, useState } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Slide, Fade } from 'react-awesome-reveal';
import styled from 'styled-components';

export default class Portfolio extends Component {
  render() {
    return (
      <PorfolioDiv id="portfolio">
        <StyledText>
          <Fade cascade damping={0.1}>
            <h4>
              <Underline>Work</Underline>
            </h4>
            <br />
            <h1>Recent Projects</h1>
          </Fade>
        </StyledText>
        <ImageListContainer>
          <ImageListWrapper>
            <ImageList
              sx={{
                width: '100%',
                maxWidth: '980px',
                margin: '0 auto',
              }}
              cols={1}
              rowHeight={300}
            >
              {itemData.map((item) => (
                <CustomImageListItem
                  key={item.img}
                  img={item.img}
                  title={item.title}
                  description={item.disc}
                  livelink={item.livelink}
                  repo={item.repo}
                />
              ))}
            </ImageList>
          </ImageListWrapper>
        </ImageListContainer>
      </PorfolioDiv>
    );
  }
}

const CustomImageListItem = ({ img, title, description, livelink, repo }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <StyledImageListItem
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <img
        src={`${img}?w=300&h=300&fit=crop&auto=format`}
        srcSet={`${img}?w=300&h=300&fit=crop&auto=format&dpr=2 2x`}
        alt={title}
        loading="lazy"
        width={330}
        height={330}
      />
      {isHovered && (
        <Overlay>
          <Title>{title}</Title>
          <Description>{description}</Description>
          <LinkContainer>
            <LiveLink href={livelink} target="_blank" rel="noopener noreferrer">
              Live-Site
            </LiveLink>
            <RepoLink href={repo} target="_blank" rel="noopener noreferrer">
              Repository
            </RepoLink>
          </LinkContainer>
        </Overlay>
      )}
    </StyledImageListItem>
  );
};
const LinkContainer = styled.div`
  display: flex;
  margin-top: 10px;
`;

const LiveLink = styled.a`
  color: #B87856;
  font-size: 18px;
  margin-right: 20px;
  text-weight: bold;
`;

const RepoLink = styled.a`
  color: #eee4db;
  font-size: 18px;
  text-weight: bold;
  margin-left: 20px;
  color: #B87856;
`;

const StyledImageListItem = styled(ImageListItem)`
  position: relative;
  overflow: hidden;
  // padding: 20px;
  margin-bottom: 20px;
`;

const PorfolioDiv = styled.div`
  background-color: #eee4db;
  width: 100%;
  margin-top: 40px;
`;
const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  color: #eee4db;
  opacity: 0;
  transition: opacity 0.6s ease-in-out;

  ${StyledImageListItem}:hover & {
    opacity: 1;
  }
`;

const Title = styled.h2`
  font-size: 30px;
  margin-bottom: 10px;
  color: #b87856;
  font-weight: bold;
`;

const Description = styled.p`
  font-size: 16px;
  text-align: center;
  width: 50%
`;


const StyledText = styled.div`
  text-align: center;
  margin-bottom: 1rem;
  padding-bottom: 1rem;

  h4 {
    margin-bottom: 0;
    color: #b87856;
    font: 16px/24px 'Avenir Pro LT', sans-serif;
  }

  h1 {
    color: #2f1611;
    font: 32px "Avenir LT Pro";
    font-weight: bold;
  }

  p {
    font-size: 2rem;
    color: #888;
  }
`;

const Underline = styled.span`
  border-bottom: 1px solid #b87856;
`;

const ImageListContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ImageListWrapper = styled.div`
  width: 100%;
`;


const itemData = [
  {
    img: 'https://res.cloudinary.com/dusaigbyn/image/upload/v1686256606/techlicious_ehgg1x.png',
    title: 'Tech-licious',
    disc:
      'CMS website for a tech blog using Node.js, Express, Handlebars, MySQL, Sequelize, and MVC design pattern.',
    keyword: 'Backend, all',
    livelink: 'https://tech-licious.herokuapp.com/',
    repo: "https://github.com/akdevndesign/tech-blog-build",
  },
  {
    img: 'https://res.cloudinary.com/dusaigbyn/image/upload/v1686446737/Screenshot_2023-06-10_at_9.25.21_PM_stzh5b.png',
    title: 'Paws and Claws',
    disc:
      'React App for a pet adoption website using React, React Router, GraphQL, Apollo, and React Hooks.',
    keyword: 'React, all',
    livelink: 'https://murmuring-waters-06827.herokuapp.com/',
    repo: "https://github.com/akdevndesign/paws-and-claws",
  },
  {
    img: 'https://res.cloudinary.com/dusaigbyn/image/upload/v1686427393/Screenshot_2023-06-10_at_3.58.25_PM_ckax2v.png',
    title: 'Digilogue',
    disc: `A react app that allows users to play a digital synthesizer. Uses React, Tone.js, and React Hooks.`,
    keyword: 'React, all',
    livelink: "https://akdevndesign.github.io/Digilogue-Synth-Build/",
    repo: "https://github.com/akdevndesign/Digilogue-Synth-Build",
  },
  {
    img: 'https://res.cloudinary.com/dusaigbyn/image/upload/v1686445364/Screenshot_2023-06-10_at_9.02.10_PM_bdatvm.png',
    title: 'FitTrack App',
    disc: `A fitness tracking app that allows users to track their workouts and progress. Uses Node.js, Express, MySQL, Sequelize, and MVC design pattern.`,
    keyword: 'Backend, all',
    livelink: 'https://fittrack.herokuapp.com/',
    repo: "https://github.com/akdevndesign/FitTrack-build",
  },
  {
    img: 'https://res.cloudinary.com/dusaigbyn/image/upload/v1686445209/Screenshot_2023-06-10_at_8.59.55_PM_ep41rf.png',
    title: 'Weather Dashboard',
    disc: `A weather dashboard that allows users to search for a city, see the current weather, and 5-day forecast. Uses OpenWeather API.`,
    keyword: 'Frontend, all',
    livelink: 'https://akdevndesign.github.io/weather-dashboard-build/',
    repo: 'https://github.com/akdevndesign/weather-dashboard-build',
  },
];