import React from 'react';
import styled from 'styled-components';

const Card = (props) => {
  const { Icon, disc, title } = props;
  const splitTitle = title.split(" ");
  const firstWord = splitTitle[0];
  const secondWord = splitTitle[1];

  return (
    <CardContainer>
      <Container>
        <IconWrapper>
          <Icon />
        </IconWrapper>
      </Container>
      <CardContent>
        <h1>
          {firstWord}
          <br />
          {secondWord}
        </h1>
        <p>{disc}</p>
      </CardContent>
    </CardContainer>
  );
};

export default Card;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 210px;
  height: 100%;
  padding: 20px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 7rem;
  height: 7rem;
  background: #b87856;
  text-align: center;
  padding: 1rem;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 85%;
    height: auto;
  }
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  h1 {
    margin-bottom: 0;
    padding-bottom: -1rem;
    padding-top: 1rem;
    font: 24px "Avenir LT Pro";
    font-weight: bold;
  }

  p {
    padding-top: .5rem;
    font-size: 1.1rem;
    color: #737272;
  }
`;
