import React from "react";
import styled from "styled-components";
import Card from "./Card";
import { Slide, Fade } from "react-awesome-reveal";

const About = () => {

  return (
    <Container className="aboutSection" id="about">
      <Fade cascade>
        <StyledText>
          <h4>
            <Underline>Skills</Underline>
          </h4>
          <br />
          <h1>Where I Shine!</h1>
          <p>
            When it comes to shining, I'm like a web development supernova! With
            my expertise in full-stack web development, marketing prowess, and
            project management wizardry, I'll make your online presence sparkle
            brighter than a disco ball at a coding party.
          </p>
        </StyledText>
      </Fade>
      <Cards>
        <Slide direction="left">
          <Card
            Icon={() => (
              <img
                src="https://res.cloudinary.com/dusaigbyn/image/upload/v1686268499/web_icon_cream_ylfczk.png"
                alt="Web Developer"
              />
            )}
            title={"Web Development"}
            disc={`1 Year Experience`}
          />
        </Slide>
        <Slide direction="left">
          <Card
            Icon={() => (
              <img
                src="https://res.cloudinary.com/dusaigbyn/image/upload/v1686268499/UX_icon_cream_krrjy4.png"
                alt="UI/UX Design"
              />
            )}
            title={"UI/UX Design"}
            disc={"1 Year Experience"}
          />
        </Slide>
        <Slide direction="right">
          <Card
            Icon={() => (
              <img
                src="https://res.cloudinary.com/dusaigbyn/image/upload/v1686268498/proj.manage_icon_cream_ar9492.png"
                alt="Project Management"
              />
            )}
            title={"Project Management"}
            disc={"5+ Years Experience"}
          />
        </Slide>
        <Slide direction="right">
          <Card
            Icon={() => (
              <img
                src="https://res.cloudinary.com/dusaigbyn/image/upload/v1686268498/marketing_icon_cream_eonju3.png"
                alt="Marketing"
              />
            )}
            title={"Marketing Management"}
            disc={"5+ Years Experience"}
          />
        </Slide>
      </Cards>
    </Container>
  );
};

export default About;
const Container = styled.div`
  background-color: #eee4db;
  width: 100%;
  justify-content: space-between;
  // max-width: 1280px;
  margin: 0 auto;
  align-items: center;

  h1 {
    text-align: center;

    h1 {
      color: #2F1611;
      font: 32px "Avenir LT Pro";
      font-weight: bold;
    }
  }
`;

const StyledText = styled.div`
  text-align: center;
  margin-bottom: 1rem;
  padding-bottom: 1rem;

  h4 {
    margin-bottom: 0;
    color: #b87856;
    font: 16px/24px 'Avenir Pro LT', sans-serif;
  }

  h1 {
    color: #2f1611;
    font: 32px "Avenir LT Pro";
    font-weight: bold;
  }

  p {
    font-size: 1.3rem;
    color: #737272;
    width: 57%; /* Set the width to 80% */
    margin: 0 auto; /* Center align the paragraph */
  }
`;

const Underline = styled.span`
  border-bottom: 1px solid #b87856;
`;

const Cards = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  padding: 1rem; /* Add some padding around the cards */

  @media (min-width: 601px) {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  @media (max-width: 600px) {
  display: flex;
  justify-content: center; /* Center align cards horizontally */
  flex-direction: column; /* Arrange cards in a column */
  align-items: center; /* Center align cards vertically */
  gap: 1rem;
  flex-wrap: wrap;
  padding: 1rem; /* Add some padding around the cards */
`;
