import React, { Component } from 'react';

export default class Footer extends Component {
  render() {
    return (
      <footer>
        <div className="row">
            <ul className="social-links">
            <p>2023 Copyright - AK Designs | All Rights Reserved</p>
            </ul>
        </div>
        <div id="go-top">
          <a className="smoothscroll" title="Back to Top" href="#home">
            <img src="https://res.cloudinary.com/dusaigbyn/image/upload/v1686687658/upicon_qwn0st.png" alt="Back to Top" />
          </a>
        </div>
      </footer>
    );
  }
}