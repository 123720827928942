import React, { Component } from 'react';
import styled from 'styled-components';
import { Slide, Fade } from 'react-awesome-reveal';

export default class ContactUs extends Component {
  render() {
    const linkedIn = 'https://www.linkedin.com/in/kelseyaaron/';
    const gitHub = 'https://github.com/akdevndesign';
    const email = 'mailto:aaron@akdevndesign.com';
    return (
      <section id="contact">
        <Fade cascade>
          <StyledText>
            <h4>
              <Underline>Contact</Underline>
            </h4>
            <br />
            <h1>Ways To Reach Me</h1>
            <p>
              Interested in collaborating or discussing a project? Let's connect and bring your
              ideas to life. Drop me a message and let's start the conversation.
            </p>
            <br />
          </StyledText>
        </Fade>
        <ContactLinks>
          <ContactLink href={linkedIn}>
            <ContactIcon src="https://res.cloudinary.com/dusaigbyn/image/upload/v1686797131/icons8-linkedin_yt1dxt.svg" alt="LinkedIn" />
            <ContactText>in/kelseyaaron</ContactText>
          </ContactLink>
          <ContactLink href={gitHub}>
            <ContactIcon src="https://res.cloudinary.com/dusaigbyn/image/upload/v1686797134/icons8-github_xw6pyu.svg" alt="GitHub" />
            <ContactText>AKdevndesign</ContactText>
          </ContactLink>
          <ContactLink href={email}>
            <ContactIcon src="https://res.cloudinary.com/dusaigbyn/image/upload/v1686797133/icons8-email_jty8of.svg" alt="Email" />
            <ContactText>aaron@akdevndesign.com</ContactText>
          </ContactLink>
        </ContactLinks>
      </section>
    );
  }
}

const ContactLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font: 2rem;
  margin-top: 2rem;
  text-align: center;
`;

const ContactLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center; /* Add this line to center the content */
  background-color: #aa6141;
  color: #eee4db;
  text-decoration: none;
  margin-bottom: 1rem;
  width: 60%;
  padding: 0.5rem;
`;

const ContactIcon = styled.img`
  font-size: 2rem;
  margin-right: 5px;
  width: 30px;
  height: 30px;

  @media (max-width: 600px) {
    width: 60px; /* Increase the width by 30px */
    height: 60px; /* Increase the height by 30px */
  }
`;

const ContactText = styled.p`
  font-size: 1.8rem;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 600px) {
    display: none; /* Hide the text on small screens */
  }
`;
const StyledText = styled.div`
  text-align: center;
  margin-bottom: 1rem;
  padding-bottom: 1rem;

  h4 {
    margin-bottom: 0;
    color: #b87856;
    font: 16px/24px 'Avenir Pro LT', sans-serif;
  }
  h1 {
    color: #eee4db;
    font: 32px "Avenir LT Pro";
    font-weight: bold;
  }

  p {
    text-align: center;
    font-size: 20.8px;
    color: #eee4db;
    width: 55%;
    margin: 0 auto;
  }
`;

const Underline = styled.span`
  border-bottom: 1px solid #b87856;
  color: #eee4db;
`;
